import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const ModalComponent = ({
  desc,
  title,
  show,
  handleModal,
  showFooter = true,
  showCancelButton = true,
  showConfirmButton = true,
  func,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        onHide={handleModal}
        className=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className="fs-5">{title}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="small">{desc}</p>
        </Modal.Body>
        {showFooter && (
          <Modal.Footer className="d-flex justify-content-end gap-3 align-items-center">
            {showCancelButton && (
              <button
                onClick={handleModal}
                className="btn btn-danger"
              >
                {t("cancel")}
              </button>
            )}
            {showConfirmButton && (
              <button
                onClick={() => {
                  func();
                }}
                className="btn btn-success"
              >
                {t("confirm")}
              </button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default ModalComponent;
