import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";
import Input from "../../components/form/input";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../components/ui/button";
import useAsyncCall from "../../hooks/useAsyncCall";
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import { useDispatch, useSelector } from "react-redux";

import {
  useSignInMutation,
  useGoogleAuthMutation,
} from "../../Redux/services/AuthApi";
import { setToken, setAuthorized } from "../../Redux/slices/UserSlice";
import ToastComponent from "../../components/toast/Toast";
import { Toaster } from "react-hot-toast";

const Signin = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const signUp = useAsyncCall();
  const googleSignUp = useAsyncCall();
  const navigate = useNavigate();
  const [userApi, { isLoading: signInLoader }] = useSignInMutation();
  const [googleAuth] = useGoogleAuthMutation();
  const { token: accessToken } = useSelector(state => state.user);

  const dispatch = useDispatch();

  const onSubmit = methods.handleSubmit(async data => {
    setIsLoading(true);

    try {
      const response = await userApi(data).unwrap();

      if (response.success) {
        dispatch(setToken(`Bearer ${response.data.accessToken}`));
        dispatch(setAuthorized());
        setSuccess(true);

        navigate("/auth/profile/card");
      } else {
        setSuccess(false);

        if (response?.errorCode) {
          setErrorText(t("SignInInvalidPassword"));
        }
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Something went wrong, try again later",
          type: "error",
        })
      );
      console.log(error);
    }
  });

  const clientId =
    "454225060874-mc34apaqi0p7drgcvrdulfrf5dfil0br.apps.googleusercontent.com";
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  }, []);

  const responseMessage = response => {
    const token = response.tokenId;
    googleSignUp.request("post", "/auth/login-google", { token }).then(e => {
      if (e.success) {
        dispatch(setToken(`Bearer ${e.data.accessToken}`));
        dispatch(setAuthorized());
        setSuccess(true);
        localStorage.setItem("token", `Bearer ${e.data.accessToken}`);
        window.location.href = "/auth/card";
      } else {
        setSuccess(false);
        if (e.errorCode) {
          setErrorText(t("SignInInvalidPassword"));
        }
      }
      setIsLoading(false);
    });
  };

  const errorMessage = error => {
    console.log(error);
  };

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />

      <div className="register-photo mt-lg-5">
        <div className="form-container">
          <div className="image-holder"></div>
          <FormProvider {...methods}>
            <form
              style={{ color: "#8f12fd!important" }}
              onSubmit={e => e.preventDefault()}
              noValidate
              autoComplete="off"
              className="container"
            >
              <h2 className="text-center">
                <strong>{t("signInText")}</strong>
              </h2>
              <div className="grid gap-5 md:grid-cols-2">
                <Input
                  {...{
                    name: "Email",
                    type: "text",
                    id: "email",
                    placeholder: t("signUpEmail"),
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpEmailRequired"),
                      },
                      maxLength: {
                        value: 40,
                        message: t("maximumCharactersAllowed", { length: 30 }),
                      },
                    },
                  }}
                />
                <Input
                  {...{
                    name: "Password",
                    type: "text",
                    id: "password",
                    placeholder: t("signUpPassword"),
                    showPasswordToggle: true,
                    type: "password",
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpPasswordRequired"),
                      },
                      maxLength: {
                        value: 30,
                        message: t("maximumCharactersAllowed", { length: 30 }),
                      },
                    },
                  }}
                />
              </div>

              <div className="form-group">
                {success && (
                  <p className="font-semibold text-green-500 mb-5 flex items-center gap-1"></p>
                )}
                <div className={!success ? "invalid-feedback d-block" : "d-none"}>
                  {errorText}
                </div>
                <Link
                  to="/auth/forgotpassword"
                  className="already"
                >
                  {t("forgetPasswordLink")}
                </Link>
                <Button
                  func={onSubmit}
                  text={t("signInButton")}
                  isLoading={signInLoader}
                />
              </div>
              <GoogleLogin
                clientId="454225060874-mc34apaqi0p7drgcvrdulfrf5dfil0br.apps.googleusercontent.com"
                onSuccess={responseMessage}
                onFailure={errorMessage}
                buttonText={t("signInWithGoogle")}
                className="google-login-button"
              />

              <div className="already">
                {t("dontHaveAnAccount")}
                <Link to="/auth/signup">{t("clickHere")}</Link>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer />
      <>
        <Toaster position="top-right" />
        <ToastComponent />
      </>
    </>
  );
};

export default Signin;
