import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useSelector } from "react-redux";
import Countdown from "../../components/countdown/countdown";
import Navbar from "../../components/navbar/navbar";
import { useTranslation } from "react-i18next";

const PremiumFeatures = () => {
  const userSubscription = useSelector(state => state.user.user.pricingPlan);
  const { t } = useTranslation();

  function formatKey(key) {
    const words = key.match(/[A-Z]?[a-z]+|[A-Z]+(?=[A-Z][a-z])/g);
    const formattedKey = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedKey;
  }
  const limitArray = Object.entries(userSubscription)
    .filter(([key]) => key.includes("Limit"))
    .map(([key, value]) => ({ [formatKey(key)]: value }));
  const permissions = Object.entries(userSubscription)
    .filter(([key]) => key.includes("can"))
    .map(([key, value]) => ({ [formatKey(key)]: value }));

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />

      <div
        className="container-xxl ms-5 ps-5 w-80"
        style={{
          marginTop: "3.5rem",
          padding: "2rem",
        }}
      >
        <Sidebar />
        <div className="px-5 w-75">
          <h1>
            {t("PremiumFeatures")}
            {userSubscription.name !== "Free" ? (
              <h3>
                {" "}
                {t("premiumName")} : {userSubscription.name}
              </h3>
            ) : (
              <h3> {t("free")}</h3>
            )}
          </h1>
          <div className="d-flex gap-4 my-3">
            {t("subscriptionDate")}{" "}
            <Countdown
              targetDate={userSubscription.userPricingPlans[0].expireDate}
            />
          </div>
          <div className="card">
            <div className="card-header">{t("featuresLimts")}</div>
            <div className="card-body">
              <ul className="list-unstyled d-flex flex-wrap gap-3 justify-content-center">
                {limitArray.map((limit, index) => (
                  <li
                    key={index}
                    className="mb-0 "
                  >
                    {Object.entries(limit).map(([key, value]) => (
                      <p
                        key={key}
                        className="mb-0 "
                      >
                        {key} : {value}
                      </p>
                    ))}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-header">{t("permissions")}</div>
            <div className="card-body">
              <ul className="list-unstyled d-flex flex-wrap gap-3 justify-content-center">
                {permissions.map((permission, index) => (
                  <li
                    key={index}
                    className="mb-0 "
                  >
                    {Object.entries(permission).map(([key, value]) => (
                      <p
                        key={key}
                        className="mb-0 "
                      >
                        {key} : {value ? "Yes" : "No"}
                      </p>
                    ))}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PremiumFeatures;
