import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import icon from "../../assets/newIcons/1.svg";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useChangeUsernameMutation } from "../../Redux/services/CardApi";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";
import profile from "../../assets/newIcons/2.svg";
import card from "../../assets/newIcons/15.svg";
import premium from "../../assets/newIcons/4.svg";
import analytics from "../../assets/newIcons/5.svg";
import referal from "../../assets/newIcons/14.svg";
import qr from "../../assets/newIcons/7.svg";
import shorturl from "../../assets/newIcons/8.svg";
import username from "../../assets/newIcons/18.svg";
import template from "../../assets/newIcons/9.svg";

export default function Sidebar({ handleShowProfileInfo, position, top }) {
  const { user } = useSelector(state => state.user);
  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const { selectedCard } = useSelector(state => state.user);
  const { t } = useTranslation();
  const [changeUsername] = useChangeUsernameMutation();
  const dispatch = useDispatch();

  // Toggle the sidebar's expanded state
  const handleToggle = () => {
    setExpanded(prev => !prev);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `https://addme.ge/auth/signup?referral=${user.username}`
    );
    setTimeout(() => {
      dispatch(setToastNotification({ message: t("linkCopied"), type: "success" }));
    }, 1000);
  };

  // Update `isMobile` state based on window width
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
    // Optionally, collapse the sidebar when resizing
    // setExpanded(false);
  };

  const handleChangeUsername = e => {
    setNewUsername(e.target.value);
  };
  const handleSendNewUsername = async () => {
    const response = await changeUsername({
      cardId: selectedCard.cardId,
      name: newUsername,
    });
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({
            message: t("usernameChangeSuccess"),
            type: "success",
          })
        );

        setNewUsername("");
        setShowUsernameModal(false);
      }
      if (response.data.errorCode === 9) {
        dispatch(
          setToastNotification({
            message: t("usernameError"),
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  // Add event listener for window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define styles for the sidebar based on screen size and expansion state
  const sidebarStyle = {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    gap: "5rem",
    // justifyContent: "space-between",
    top: top || "0",
    left: "0",
    marginTop: "4rem",
    width: isMobile ? (expanded ? "100%" : "0") : expanded ? "20rem" : "5rem",
    height: "100vh",
    backgroundColor: "white",
    padding: isMobile && !expanded ? "0" : "10px",
    zIndex: "1000",
    transition: "width 0.3s ease, padding 0.3s ease",
    overflow: "hidden",
    boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
  };

  // Define styles for the toggle button
  const toggleButtonStyle = {
    position: "fixed",
    top: isMobile ? "10px" : "5rem",

    right: isMobile && "10px",
    left: !isMobile && "25px",
    zIndex: "1100",
    transition: isMobile ? "none" : "left 0.3s ease",
  };

  // Define styles for the overlay (only for mobile when sidebar is expanded)
  const overlayStyle =
    isMobile && expanded
      ? {
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "900",
          transition: "opacity 0.3s ease",
        }
      : {
          display: "none",
        };

  // Define styles for the button
  const buttonStyle = {
    width: "9rem",
    height: "5rem",
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "50%",
    transition: "background-color 0.3s ease",
    gap: "1rem",
  };

  // Define styles for the button text
  const buttonTextStyle = {
    whiteSpace: "nowrap",
    textDecoration: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "opacity 0.3s ease",
    opacity: expanded || isMobile ? 1 : 0,
    pointerEvents: expanded || isMobile ? "auto" : "none",
  };

  // Define a function to handle icon clicks and navigation
  const handleIconClick = path => {
    navigate(path);
  };

  return (
    <>
      {/* Toggle Button */}
      <div style={toggleButtonStyle}>
        <img
          src={icon}
          alt="icon"
          style={{ width: "2rem", cursor: "pointer" }}
          onClick={handleToggle}
        />
      </div>

      {/* Sidebar */}
      <nav style={sidebarStyle}>
        <ul className="list-unstyled text-small d-flex flex-column gap-1 mt-5">
          {/* Menu Item 1 */}
          <li className="d-flex align-items-center gap-4">
            <NavLink
              to="/auth/profile/accountDetailsinfo"
              className={({ isActive }) =>
                `d-flex align-items-center gap-2 ${
                  isActive
                    ? "nav-link active text-white bg-primary rounded px-3 py-2 w-100"
                    : "nav-link px-3 py-2"
                }`
              }
            >
              <div
                style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
                onClick={() => handleIconClick("/auth/profile/accountDetailsinfo")}
              >
                <img
                  src={profile}
                  alt=""
                  style={{
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                  }}
                />
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",
                  textDecoration: "none",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  transition: "opacity 0.3s ease",
                  opacity: expanded || isMobile ? 1 : 0,
                  pointerEvents: expanded || isMobile ? "auto" : "none",
                }}
              >
                {t("AccountDetails")}
              </span>
            </NavLink>
          </li>

          {/* Menu Item 2 */}
          <li className="d-flex align-items-center gap-4">
            <NavLink
              to="/auth/profile/card"
              className={({ isActive }) =>
                `d-flex align-items-center gap-2 ${
                  isActive
                    ? "nav-link active text-white bg-primary rounded px-3 py-2 w-100"
                    : "nav-link px-3 py-2"
                }`
              }
            >
              <div
                style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
                onClick={() => handleIconClick("/auth/profile/card")}
              >
                <img
                  src={card}
                  alt=""
                  style={{
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                  }}
                />
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",
                  textDecoration: "none",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  transition: "opacity 0.3s ease",
                  opacity: expanded || isMobile ? 1 : 0,
                  pointerEvents: expanded || isMobile ? "auto" : "none",
                }}
              >
                {t("DigitalCard")}
              </span>
            </NavLink>
          </li>

          {/* Menu Item 3 */}
          <li className="d-flex align-items-center gap-4">
            <NavLink
              to="/auth/profile/premiumfeatures"
              className={({ isActive }) =>
                `d-flex align-items-center gap-2 ${
                  isActive
                    ? "nav-link active text-white bg-primary rounded px-3 py-2 w-100"
                    : "nav-link px-3 py-2"
                }`
              }
            >
              <div
                style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
                onClick={() => handleIconClick("/auth/profile/premiumfeatures")}
              >
                <img
                  src={premium}
                  alt=""
                  style={{
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                  }}
                />
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",
                  textDecoration: "none",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  transition: "opacity 0.3s ease",
                  opacity: expanded || isMobile ? 1 : 0,
                  pointerEvents: expanded || isMobile ? "auto" : "none",
                }}
              >
                {t("PremiumFeatures")}
              </span>
            </NavLink>
          </li>
          {user?.pricingPlan?.canAnalytics ? (
            <li className="d-flex align-items-center gap-4">
              <NavLink
                to="/analytics"
                className={({ isActive }) =>
                  `d-flex align-items-center gap-2 ${
                    isActive
                      ? "nav-link active text-white bg-primary rounded px-3 py-2 w-100"
                      : "nav-link px-3 py-2"
                  }`
                }
              >
                <div
                  style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
                  onClick={() => handleIconClick("/analytics")}
                >
                  <img
                    src={analytics}
                    alt=""
                    style={{
                      width: "2rem",
                      height: "2rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    textDecoration: "none",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    transition: "opacity 0.3s ease",
                    opacity: expanded || isMobile ? 1 : 0,
                    pointerEvents: expanded || isMobile ? "auto" : "none",
                  }}
                >
                  {t("Analytics")}
                </span>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {/* Menu Item 4 */}

          {user?.pricingPlan?.qrLimit !== 0 ? (
            <li className="d-flex align-items-center gap-4">
              <NavLink
                to="/auth/profile/customQRs"
                className={({ isActive }) =>
                  `d-flex align-items-center gap-2 ${
                    isActive
                      ? "nav-link active text-white bg-primary rounded px-3 py-2 w-100"
                      : "nav-link px-3 py-2"
                  }`
                }
              >
                <div
                  style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
                  onClick={() => handleIconClick("/auth/profile/customQRs")}
                >
                  <img
                    src={qr}
                    alt=""
                    style={{
                      width: "2rem",
                      height: "2rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    textDecoration: "none",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    transition: "opacity 0.3s ease",
                    opacity: expanded || isMobile ? 1 : 0,
                    pointerEvents: expanded || isMobile ? "auto" : "none",
                  }}
                >
                  {t("CreateQRCode")}
                </span>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {/* Menu Item 5 */}

          {user?.pricingPlan?.shortLinkLimit !== 0 ? (
            <li className="d-flex align-items-center gap-4">
              <NavLink
                to="/auth/profile/shortURLs"
                className={({ isActive }) =>
                  `d-flex align-items-center gap-2 ${
                    isActive
                      ? "nav-link active text-white bg-primary rounded px-3 py-2 w-100"
                      : "nav-link px-3 py-2"
                  }`
                }
              >
                <div
                  className="d-flex align-items-center gap-2 "
                  style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
                  onClick={() => handleIconClick("/auth/profile/shortURLs")}
                >
                  <img
                    src={shorturl}
                    alt=""
                    style={{
                      width: "2rem",
                      height: "2rem",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    textDecoration: "none",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    transition: "opacity 0.3s ease",
                    opacity: expanded || isMobile ? 1 : 0,
                    pointerEvents: expanded || isMobile ? "auto" : "none",
                  }}
                >
                  {t("ShortLinks")}
                </span>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {/* Menu Item 6 */}
          {user?.pricingPlan?.canChangeUsername ? (
            <li
              className="d-flex align-items-center gap-4 position-relative"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setShowUsernameModal(true)}
              onMouseEnter={() => setShowLink(true)}
              onMouseLeave={() => setShowLink(false)}
            >
              <div
                className="px-3 py-3"
                style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
              >
                <img
                  src={username}
                  alt=""
                  style={{ width: "2rem", height: "2rem" }}
                />
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",
                  textDecoration: "none",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  transition: "opacity 0.3s ease",
                  opacity: expanded || isMobile ? 1 : 0,
                  pointerEvents: expanded || isMobile ? "auto" : "none",
                }}
              >
                {t("changeURL")}
              </span>
              {showLink && (
                <div
                  className="px-3 py-3 position-absolute w-100"
                  style={{
                    width: "2rem",
                    textAlign: "center",
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                    bottom: 0, // Position it at the bottom of the parent container
                    left: 0,
                    right: 0,
                    transform: "translateY(100%)", // Slightly move it outside the container
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {window.location.origin}/{selectedCard?.userName}
                  </span>
                </div>
              )}
            </li>
          ) : (
            ""
          )}

          {user?.pricingPlan?.canChooseTemplate ? (
            <li
              className="d-flex align-items-center gap-4"
              style={{
                cursor: "pointer",
              }}
            >
              <div
                className="px-3 py-3"
                style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
              >
                <img
                  src={template}
                  alt=""
                  style={{ width: "2rem", height: "2rem" }}
                />
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",
                  textDecoration: "none",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  transition: "opacity 0.3s ease",
                  opacity: expanded || isMobile ? 1 : 0,
                  pointerEvents: expanded || isMobile ? "auto" : "none",
                }}
              >
                {t("template")}
              </span>
            </li>
          ) : (
            ""
          )}

          <li
            className="d-flex align-items-center gap-4"
            style={{
              cursor: "pointer",
            }}
            onClick={copyToClipboard}
          >
            <div
              className="px-3 py-3"
              style={{ width: "2rem", textAlign: "center", cursor: "pointer" }}
            >
              <img
                src={referal}
                alt=""
                style={{ width: "2rem", height: "2rem" }}
              />
            </div>
            <span
              style={{
                whiteSpace: "nowrap",
                textDecoration: "none",
                overflow: "hidden",
                textOverflow: "ellipsis",
                transition: "opacity 0.3s ease",
                opacity: expanded || isMobile ? 1 : 0,
                pointerEvents: expanded || isMobile ? "auto" : "none",
              }}
            >
              {t("referalLink")}
            </span>
          </li>
        </ul>

        {/* <button
          style={buttonStyle}
          onClick={() => {
            handleLogout();
          }}
          className="px-3 py-2"
        >
          <IconLogout />
          <span style={buttonTextStyle}>Log out</span>
        </button> */}
      </nav>

      {/* Overlay for Mobile when Sidebar is Expanded */}
      {isMobile && expanded && (
        <div
          onClick={handleToggle}
          style={overlayStyle}
        />
      )}
      <Modal
        onHide={() => setShowUsernameModal(false)}
        show={showUsernameModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center gap-3 ">
            <div className="d-flex flex-column gap-3 ">
              <h6 className="fs-5">
                {t("cardName")} - {selectedCard?.userName}
              </h6>
              <h6 className="small">{t("changeUsername")}</h6>

              <input
                type="text"
                id="newUsername"
                name="newUsername"
                value={newUsername}
                onChange={handleChangeUsername}
                className="form-control"
                placeholder={t("usernamePlaceholder")}
              />
            </div>
            <button
              className="btn btn-primary-gradient align-self-end "
              onClick={handleSendNewUsername}
            >
              {t("send")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
