import React, { useEffect, useState } from "react";
import Input from "../../components/form/input";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../../components/ui/button";
import useAsyncCall from "../../hooks/useAsyncCall";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import { useDispatch } from "react-redux";
import { setToken, setAuthorized } from "../../Redux/slices/UserSlice";
import { useSignUpMutation } from "../../Redux/services/AuthApi";
import ToastComponent from "../../components/toast/Toast";
import { Toaster } from "react-hot-toast";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";

const Signup = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");

  const googleSignUp = useAsyncCall();
  const dispatch = useDispatch();
  const [signup] = useSignUpMutation();

  const [searchParams, setSearchParams] = useSearchParams();
  let referral = searchParams.get("referral");
  const navigate = useNavigate();

  const onSubmit = methods.handleSubmit(async data => {
    setIsLoading(true);

    const additionalParams = {
      Referral: referral,
    };
    const requestData = { ...data, ...additionalParams };
    const response = await signup(requestData).unwrap();
    console.log(response);
    try {
      if (response.data) {
        dispatch(setToken(`Bearer ${response.data.accessToken}`));
        dispatch(setAuthorized());

        // localStorage.setItem("token", `Bearer ${res.data.accessToken}`);
        // window.location.href = "/auth/card";
        navigate("/auth/profile/card");
      } else {
        if (response.errorCode) {
          setErrorText(t("emailAlreadyExists"));
        }
      }
    } catch (error) {
      dispatch(
        setToastNotification({
          message: error.message,
          type: "error",
        })
      );
      console.log(error);
    }
    setIsLoading(false);

    // signUp.request("post", "/auth/register", requestData).then(e => {
    //   console.log(e);
    //   if (e.success) {
    //     setSuccess(true);
    // localStorage.setItem("token", `Bearer ${e.data.accessToken}`);
    //     window.location.href = "/auth/card";
    //   } else {
    //     setSuccess(false);
    //     if (e.errorCode) {
    //       setErrorText(t("emailAlreadyExists"));
    //     }
    //   }
    //   setIsLoading(false);
    // });
  });

  // const onSubmit = methods.handleSubmit(data => {
  //   setIsLoading(true);
  //   console.log(referral);
  //   const additionalParams = {
  //     Referral: referral,
  //   };
  //   const requestData = { ...data, ...additionalParams };
  //   signUp.request("post", "/auth/register", requestData).then(e => {
  //     console.log(e);
  //     if (e.success) {
  //       setSuccess(true);
  //       localStorage.setItem("token", `Bearer ${e.data.accessToken}`);
  //       window.location.href = "/auth/card";
  //     } else {
  //       setSuccess(false);
  //       if (e.errorCode) {
  //         setErrorText(t("emailAlreadyExists"));
  //       }
  //     }
  //     setIsLoading(false);
  //   });
  // });

  // const onSubmit = methods.handleSubmit(data => {
  //   setIsLoading(true);
  //   signUp.request("post", "/auth/register", data).then(e => {
  //     console.log(e);
  //     if (e.success) {
  //       setSuccess(true);
  //       localStorage.setItem("token", `Bearer ${e.data.accessToken}`);
  //       window.location.href = "/auth/card";
  //     } else {
  //       setSuccess(false);
  //       if (e.errorCode) {
  //         setErrorText(t("emailAlreadyExists"));
  //       }
  //     }
  //     setIsLoading(false);
  //   });
  // });
  const responseMessage = response => {
    const token = response.tokenId;
    googleSignUp.request("post", "/auth/login-google", { token }).then(e => {
      if (e.success) {
        setSuccess(true);
        localStorage.setItem("token", `Bearer ${e.data.accessToken}`);
        window.location.href = "/auth/card";
      } else {
        setSuccess(false);
        if (e.errorCode) {
          setErrorText(t("SignInInvalidPassword"));
        }
      }
      setIsLoading(false);
    });
  };
  const errorMessage = error => {
    console.log("error", error);
  };

  const clientId =
    "454225060874-mc34apaqi0p7drgcvrdulfrf5dfil0br.apps.googleusercontent.com";
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  }, []);

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div className="register-photo mt-0 pt-3 pt-lg-5  mt-lg-5 ">
        <div className="form-container">
          <div className="image-holder"></div>
          <FormProvider {...methods}>
            <form
              onSubmit={e => e.preventDefault()}
              noValidate
              autoComplete="off"
              className="container"
            >
              <div className="grid gap-5 md:grid-cols-2">
                <Input
                  {...{
                    name: "Firstname",
                    type: "text",
                    id: "firstname",
                    placeholder: t("signUpFirstname"),
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpFirstnameRequired"),
                      },
                      maxLength: {
                        value: 40,
                        message: t("maximumCharactersAllowed", { length: 30 }),
                      },
                    },
                  }}
                />
                <Input
                  {...{
                    name: "Lastname",
                    type: "text",
                    id: "lastname",
                    placeholder: t("signUpLastname"),
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpLastnameRequired"),
                      },
                      maxLength: {
                        value: 40,
                        message: t("maximumCharactersAllowed", { length: 30 }),
                      },
                    },
                  }}
                />
                <Input
                  {...{
                    name: "Email",
                    type: "email",
                    id: "email",
                    placeholder: t("signUpEmail"),
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpEmailRequired"),
                      },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: t("signUpEmailNotValid"),
                      },
                    },
                  }}
                />
                <Input
                  {...{
                    name: "Password",
                    type: "password",
                    id: "password",
                    placeholder: t("signUpPassword"),
                    autoComplete: "new-password",
                    showPasswordToggle: true,
                    type: "password",
                    compareFrom: {
                      key: "ConfirmPassword",
                      message: t("signUpPasswordsDontMatch"),
                    },
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpPasswordRequired"),
                      },
                      minLength: {
                        value: 6,
                        message: t("signUpPasswordLenghtRequired", { length: 6 }),
                      },
                      pattern: {
                        message: t("signUpPasswordSpaceNotAllowed"),
                        value: /^\S*$/,
                      },
                    },
                  }}
                />
                <Input
                  {...{
                    name: "ConfirmPassword",
                    type: "password",
                    id: "confirmPassword",
                    placeholder: t("signUpConfirmPassword"),
                    autoComplete: "new-password",
                    showPasswordToggle: true,
                    compareTo: {
                      key: "Password",
                      message: t("signUpPasswordsDontMatch"),
                    },
                  }}
                />
                <Input
                  {...{
                    name: "AcceptTerms",
                    type: "checkbox",
                    id: "acceptTerms",
                    label: t("signUpTermsText", { url: "/terms" }),
                    validation: {
                      required: {
                        value: true,
                        message: t("signUpAcceptTermsRequired"),
                      },
                    },
                  }}
                />
              </div>

              <div className="form-group">
                <div className={!success ? "invalid-feedback d-block" : "d-none"}>
                  {errorText}
                </div>
                <Button
                  func={onSubmit}
                  text={t("signUpButton")}
                  isLoading={isLoading}
                />
              </div>
              <GoogleLogin
                clientId="454225060874-mc34apaqi0p7drgcvrdulfrf5dfil0br.apps.googleusercontent.com"
                onSuccess={responseMessage}
                onFailure={errorMessage}
                buttonText={t("signInWithGoogle")}
                className="google-login-button" // Apply the CSS class
              />
              <div className="already">
                {t("alreadyHaveAnAccount")}
                <Link
                  to="/auth/signin"
                  className="text-primary  "
                >
                  {t("clickHere")}
                </Link>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <Footer />
      <>
        <Toaster position="top-right" />
        <ToastComponent />
      </>
    </>
  );
};

export default Signup;
