import React, { useState, useEffect } from "react";
import useAsyncCall from "../../hooks/useAsyncCall";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import icon11 from "../../assets/newIcons/11.svg";
import icon12 from "../../assets/newIcons/12.svg";
import icon13 from "../../assets/newIcons/13.svg";
import Navbar from "../../components/navbar/navbar";

import Flag from "react-world-flags";
import { Link } from "react-router-dom";
import {
  useLazyCardAnalyticsQuery,
  useLazyType1AnalyticsQuery,
} from "../../Redux/services/AnalyticsApi";
import { jwtDecode } from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";

function Analytics() {
  const { token, selectedUserId, selectedCard } = useSelector(state => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { t } = useTranslation();
  const [fetch, { data: analyticsData, isSuccess: urlDataSuccess }] =
    useLazyCardAnalyticsQuery();
  const [
    fetchTypeAnalytics,
    { data: typeAnalyticsData, isSuccess: typeDataSuccess },
  ] = useLazyType1AnalyticsQuery();

  const [toggleAnalytics, setToggleAnalytics] = useState({
    showCardAnalytics: true,
    showType1Analytics: false,
    showType2Analytics: false,
  });

  const handleToggleShowUrlAnalytics = type => {
    setToggleAnalytics(prev => ({
      ...prev,
      showCardAnalytics: type === "showCardAnalytics",
      showType1Analytics: type === "showType1Analytics",
      showType2Analytics: type === "showType2Analytics",
    }));
  };

  const decodedToken = token ? jwtDecode(token) : null;

  useEffect(() => {
    const currentUTC = new Date();
    currentUTC.setUTCHours(currentUTC.getUTCHours() + 4);

    const formattedStartDate = new Date(currentUTC.getTime() - 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16);

    const formattedEndDate = currentUTC.toISOString().slice(0, 16);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);

    const button = document.getElementById("init");
    const clickButton = () => {
      if (button) {
        button.click();
      }
    };
    const timeoutId = setTimeout(clickButton, 100);
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      fetch({ username: selectedCard.guid, startDate, endDate });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSecondQRcodeData = async () => {
    setIsLoading(true);
    try {
      const typeId = 2;
      const guid = selectedCard.guid;

      fetchTypeAnalytics({ guid, typeId, startDate, endDate });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchFirstQRcodeData = async () => {
    setIsLoading(true);
    try {
      const typeId = 1;
      const guid = selectedCard.guid;

      fetchTypeAnalytics({ guid, typeId, startDate, endDate });
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterClick = () => {
    if (toggleAnalytics.showCardAnalytics) {
      fetchData();

      return;
    } else if (toggleAnalytics.showType1Analytics) {
      handleShowType1Analytics();

      return;
    } else if (toggleAnalytics.showType2Analytics) {
      handleShowType2Analytics();

      return;
    }
  };

  const handleShowType1Analytics = () => {
    fetchFirstQRcodeData();
  };

  const handleShowType2Analytics = () => {
    fetchSecondQRcodeData();
  };

  return (
    <>
      {" "}
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <div
        style={{
          marginTop: "4rem",
          marginLeft: "6rem",
          padding: "0 6rem 0 2rem",
        }}
        className="container-xxl position-relative "
      >
        <Sidebar
          position={"-19rem"}
          top={"0"}
        />

        <h1>{t("analyticsDashboard")}</h1>
        <h2>
          {t("cardName")}: {selectedCard?.cardName}
        </h2>
        <h2>
          {t("CardURL")} :{" "}
          <a
            href={`${window.location.origin}/${selectedCard?.userName}`}
            target="_blank"
            rel="noreferrer"
          >
            {`${window.location.origin}/${selectedCard?.userName}`}
          </a>
        </h2>
        <div className="d-flex align-items-center  my-5 gap-3 ">
          <button
            className={`btn d-flex flex-column align-items-center justify-content-center  ${
              toggleAnalytics.showCardAnalytics ? "btn-primary-gradient" : ""
            }`}
            onClick={() => {
              fetchData();
              handleToggleShowUrlAnalytics("showCardAnalytics");
            }}
          >
            <img
              src={icon13}
              alt=""
              width={50}
              height={50}
            />
            {t("cardUrlAnalytics")}
          </button>

          <button
            className={`btn d-flex flex-column align-items-center justify-content-center ${
              toggleAnalytics.showType1Analytics ? "btn-primary-gradient" : ""
            }`}
            onClick={() => {
              handleToggleShowUrlAnalytics("showType1Analytics");
              handleShowType1Analytics();
            }}
          >
            <img
              src={icon12}
              alt=""
              width={50}
              height={50}
            />
            {t("QRurlAnalytics")}
          </button>
          <button
            className={`btn d-flex flex-column align-items-center justify-content-center ${
              toggleAnalytics.showType2Analytics ? "btn-primary-gradient" : ""
            }`}
            onClick={() => {
              handleToggleShowUrlAnalytics("showType2Analytics");
              handleShowType2Analytics();
            }}
          >
            <img
              src={icon11}
              alt=""
              width={50}
              height={50}
            />
            {t("QRsaveContact")}
          </button>
        </div>
        <div className="d-flex flex-md-row flex-column gap-2">
          <div className="d-flex flex-column gap-3">
            <label>{t("startDate")}: </label>
            <input
              type="datetime-local"
              value={startDate}
              className="border-primary-gradient rounded-4 border-2 p-1"
              onChange={e => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column gap-3">
            <label>{t("endDate")}: </label>
            <input
              type="datetime-local"
              value={endDate}
              className="border-primary-gradient rounded-4 border-2 p-1"
              onChange={e => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column justify-content-end">
            <button
              id="init"
              onClick={handleFilterClick}
              className="btn btn-primary-gradient"
            >
              {t("filter")}
            </button>
          </div>
        </div>

        {isLoading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <>
          {urlDataSuccess &&
          analyticsData?.data &&
          toggleAnalytics.showCardAnalytics ? (
            <div className="mt-5">
              <h2 className="fs-5">
                {t("ViewsintheSelectedDateRange")}:{" "}
                {analyticsData?.data?.totalViewCount}
              </h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("ViewsbyCountries")}</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {analyticsData?.data?.countryViews.map(country => (
                    <tr key={country}>
                      <td
                        key={country.countryName}
                        className="w-75"
                      >
                        <Flag
                          code={country.countryCode}
                          style={{
                            width: "30px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />

                        {country.countryName}
                      </td>
                      <td className="w-25">
                        {country.viewCount} {t("views")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className="table">
                <thead>
                  <tr className="w-100">
                    <th>{t("ViewsbyCities")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {analyticsData?.data?.cityViews.map(city => (
                    <tr className="w-100">
                      <td
                        key={city.cityName}
                        className="w-75"
                      >
                        {city.cityName}
                      </td>
                      <td className="w-25">
                        {city.viewCount} {t("views")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </>
        <>
          {typeDataSuccess &&
          typeAnalyticsData?.data &&
          toggleAnalytics.showType1Analytics | toggleAnalytics.showType2Analytics ? (
            <div className="mt-5">
              <h2 className="fs-5">
                {t("ViewsintheSelectedDateRange")}:{" "}
                {typeAnalyticsData?.data?.totalViewCount}
              </h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("ViewsbyCountries")}</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {typeAnalyticsData?.data?.countryViews.map(country => (
                    <tr key={country}>
                      <td
                        key={country.countryName}
                        className="w-75"
                      >
                        <Flag
                          code={country.countryCode}
                          style={{
                            width: "30px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                        />

                        {country.countryName}
                      </td>
                      <td className="w-25">
                        {country.viewCount} {t("views")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className="table">
                <thead>
                  <tr className="w-100">
                    <th>{t("ViewsbyCities")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {typeAnalyticsData?.data?.cityViews.map(city => (
                    <tr className="w-100">
                      <td
                        key={city.cityName}
                        className="w-75"
                      >
                        {city.cityName}
                      </td>
                      <td className="w-25">
                        {city.viewCount} {t("views")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </>

        {decodedToken?.Role === "SuperAdmin" ? (
          <Link
            // onClick={() => dispatch(unSelectUserId())}
            to={`/admin/users/${selectedUserId}`}
            className="btn btn-primary-gradient"
          >
            Back To User Page
          </Link>
        ) : decodedToken?.Role === "Admin" ? (
          <Link
            // onClick={() => dispatch(unSelectUserId())}
            to={`/admin/users/${selectedUserId}`}
            className="btn btn-primary-gradient"
          >
            Back To User Page
          </Link>
        ) : (
          <Link
            to="/auth/profile/card"
            className="btn btn-primary-gradient"
          >
            {t("backtoprofile")}
          </Link>
        )}
      </div>
    </>
  );
}

export default Analytics;
