import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalComponent from "../../components/modal/Modal";
import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import {
  useGetShortURLsQuery,
  useDeleteShortURLMutation,
  useCreateShortURLMutation,
} from "../../Redux/services/ShortURLApi";
import analyticsIcon from "../../assets/newIcons/16.svg";
import deleteIcon from "../../assets/newIcons/17.svg";

import { useSelector, useDispatch } from "react-redux";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";
import ShortUrlAnalytics from "../shorturlanalytics/shorturlanalytics";

export default function ShortURLs() {
  const [shortUrl, setShortUrl] = useState({
    name: "",
    link: "",
  });
  const [show, setShow] = useState(false);
  const { data: shortUrls } = useGetShortURLsQuery();
  const dispatch = useDispatch();
  const [selectedShortUrl, setSelectedShortUrl] = useState(shortUrls?.data[0]);

  const { t } = useTranslation();
  const [createShortURL] = useCreateShortURLMutation();
  const [deleteShortURL] = useDeleteShortURLMutation();

  const { user } = useSelector(state => state.user);

  const handleShortURLValues = e => {
    setShortUrl(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCreateURL = async () => {
    const response = await createShortURL(shortUrl);
    if (response.data.success) {
      dispatch(
        setToastNotification({ message: t("shortURLCreate"), type: "success" })
      );
    }

    setShortUrl({
      name: "",
      link: "",
    });
  };

  const handleDeleteURL = async id => {
    const response = await deleteShortURL(id);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("shortURLDelete"), type: "success" })
        );
        setShow(false);
      }
    } catch (err) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
      console.log(err);
    }
  };

  const handleModal = (url = null) => {
    setSelectedShortUrl(url); // Set the short URL to be deleted
    setShow(!show); // Toggle the modal
  };

  return (
    <div>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <Sidebar />
      <div
        className="container-xxl ms-5  row"
        style={{
          marginTop: "1.5rem",
          padding: "3rem",
        }}
      >
        <ModalComponent
          show={show}
          handleModal={() => handleModal(null)}
          title={t("deleteShortURL")}
          desc={`${t("confirmDeleteShortURL")} ${selectedShortUrl?.name}?`}
          func={() => handleDeleteURL(selectedShortUrl?.id)}
        />
        <div className="col-12 col-md-8">
          <h1 className="fs-3">{t("yourShortURLs")}</h1>
          <div className="mt-3 d-flex flex-column gap-2">
            <input
              type="text"
              name="name"
              className="form-control mt-3"
              value={shortUrl.name}
              onChange={handleShortURLValues}
              placeholder={t("nameURL")}
            />
            <input
              type="text"
              name="link"
              className="form-control "
              value={shortUrl.link}
              onChange={handleShortURLValues}
              placeholder={
                localStorage.getItem("language") === "en"
                  ? t("enterURL")
                  : t("shortURL")
              }
            />
            <button
              className="btn btn-primary-gradient mt-3"
              disabled={
                user?.pricingPlan?.shortLinkLimit === shortUrls?.data?.length
              }
              onClick={handleCreateURL}
            >
              {t("createShortURL")}
            </button>
          </div>
          <div
            className="table-responsive"
            style={{
              cursor: "default",
            }}
          >
            <table className="table mt-5 w-100">
              <thead>
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("URL")}</th>
                  <th>{t("link")}</th>
                  {user?.pricingPlan?.canAnalytics && <th>{t("analytics")}</th>}

                  <th>{t("actions")}</th>
                </tr>
              </thead>
              <tbody className="small">
                {shortUrls?.data?.map(e => (
                  <tr key={e.id}>
                    <td>{e.name}</td>
                    <td>
                      <a
                        href={`${window.location.origin}/u/${e.value}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {`${window.location.origin}/u/${e.value}`}
                      </a>
                    </td>

                    <td>{e.original}</td>
                    {user?.pricingPlan?.canAnalytics && (
                      <td>
                        <span
                          to={`/shorturlanalytics/${e?.guid}`}
                          onClick={() => setSelectedShortUrl(e)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={analyticsIcon}
                            alt="analytics"
                            width={30}
                            height="30"
                          />
                        </span>
                      </td>
                    )}

                    <td>
                      <span
                        style={{ cursor: "pointer" }}
                        className=""
                        onClick={() => {
                          // handleDeleteURL(e.id);
                          handleModal(e);
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="delete"
                          width={30}
                          height="30"
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="my-3 ">
            <Link
              className="btn btn-primary-gradient"
              to="/auth/profile/card"
            >
              {t("backToProfile")}
            </Link>
          </div>
        </div>
        {user?.pricingPlan?.canAnalytics ? (
          <div className="col-12 col-md-4">
            <ShortUrlAnalytics
              shortUrl={selectedShortUrl}
              showHeader={false}
              showMargin={false}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
