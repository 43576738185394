import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/navbar/navbar";
import {
  useCreateCustomQRMutation,
  useGetCustomQRQuery,
  useDeleteCustomQRMutation,
} from "../../Redux/services/UserApi";
import { useSelector, useDispatch } from "react-redux";
import { setToastNotification } from "../../Redux/slices/ToastNotificationSlice";
import Sidebar from "../../components/sidebar/Sidebar";
import QRAnalytics from "../qranalytics/qranalytics";
import analyticsIcon from "../../assets/newIcons/16.svg";
import deleteIcon from "../../assets/newIcons/17.svg";
import download from "../../assets/newIcons/download.svg";
import ModalComponent from "../../components/modal/Modal";

export default function CustomQRs() {
  const [qrValue, setQrValue] = useState({
    link: "",
    name: "",
  });
  const [show, setShow] = useState(false);
  const [selectedQR, setSelectedQR] = useState(null);
  const { data: qrs } = useGetCustomQRQuery();
  const [createCustomQR] = useCreateCustomQRMutation();
  const [deleteCustomQR] = useDeleteCustomQRMutation();

  const { t } = useTranslation();

  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const handleQRValues = e => {
    setQrValue(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCreateQR = async () => {
    const response = await createCustomQR({ url: qrValue.link, name: qrValue.name });

    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("QRCodeCreate"), type: "success" })
        );
      }
    } catch (err) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }

    setQrValue({
      link: "",
      name: "",
    });
  };
  const handleDeleteQR = async id => {
    const response = await deleteCustomQR(id);
    try {
      if (response.data.success) {
        dispatch(
          setToastNotification({ message: t("QRCodeDelete"), type: "success" })
        );
      }
      setShow(false);
    } catch (err) {
      dispatch(
        setToastNotification({ message: "Something went wrong", type: "error" })
      );
    }
  };

  const handleModal = qr => {
    setSelectedQR(qr);
    setShow(!show);
  };

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Navbar
        links={[
          {
            name: t("menuHome"),
            url: "home",
            notActive: true,
          },
          {
            name: t("menuAbout"),
            url: "about",
          },
          {
            name: t("menuFeature"),
            url: "feature",
          },
          {
            name: t("menuPricing"),
            url: "pricing",
          },
          // {
          //   name: t("menuReview"),
          //   url: "review",
          // },
          {
            name: t("menuContact"),
            url: "contact",
          },
        ]}
      />
      <Sidebar />
      <div
        className="container-xxl row "
        style={{
          marginTop: "1.5rem",
          padding: "3rem 3rem 0 7rem",
        }}
      >
        <div className="col-md-6 col-12">
          <div className="mt-3 d-flex flex-column gap-2 ">
            <h1 className="fs-3">{t("CreateQRCode")}</h1>
            <input
              type="text"
              name="name"
              className="form-control mt-3"
              placeholder={t("nameQr")}
              value={qrValue.name}
              onChange={e => handleQRValues(e)}
            />
            <input
              type="text"
              name="link"
              className="form-control"
              placeholder={t("enterURL")}
              value={qrValue.link}
              onChange={e => handleQRValues(e)}
            />
            <button
              className="btn btn-primary-gradient mt-3"
              disabled={user?.pricingPlan?.qrLimit === qrs?.data?.length}
              onClick={handleCreateQR}
            >
              {t("generateQR")}
            </button>
          </div>
          <table className="table mt-3 ">
            <thead>
              <tr>
                <th>{t("name")}</th>

                <th className="d-flex justify-content-center">{t("qrCode")}</th>
                <th className="">{t("Download")}</th>
                {user?.pricingPlan?.canAnalytics && <th>{t("analytics")}</th>}
                <th>{t("cancel")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {qrs?.data?.map(qr => (
                <tr key={qr.id}>
                  <td>{qr.name}</td>

                  <td className="d-flex justify-content-center">
                    <img
                      src={qr.qr}
                      alt={qr.name}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </td>
                  <td>
                    <span
                      onClick={() => handleDownload(qr.qr, `QR_${qr.name}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={download}
                        alt="download"
                        width={30}
                        height={30}
                      />
                    </span>
                  </td>
                  {user?.pricingPlan?.canAnalytics && (
                    <td className=" ">
                      <span
                        onClick={() => {
                          setSelectedQR(qr);
                          console.log(qr);
                        }}
                        className="d-flex justify-content-center"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={analyticsIcon}
                          alt="analytics"
                          width={30}
                          height={30}
                        />
                      </span>
                    </td>
                  )}

                  <td className="">
                    <div className="d-flex align-items-center gap-2">
                      <span
                        className="d-flex justify-content-center"
                        onClick={() => handleModal(qr)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={deleteIcon}
                          alt="delete"
                          width={30}
                          height={30}
                        />
                        <img
                          src=""
                          alt=""
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mb-3">
            <Link
              className="btn btn-primary-gradient mt-3"
              to="/auth/profile/card"
            >
              {t("backToProfile")}
            </Link>
          </div>
        </div>
        {user?.pricingPlan?.canAnalytics ? (
          <div className="col-md-6 col-12">
            <QRAnalytics
              qr={selectedQR}
              showMargin={false}
              showHeader={false}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <ModalComponent
        show={show}
        handleModal={() => handleModal(null)}
        title={t("deleteQR")}
        desc={`${t("confirmDeleteQR")} ${selectedQR?.name}?`}
        func={() => handleDeleteQR(selectedQR?.id)}
      />
    </>
  );
}
